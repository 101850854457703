<script>
import store from "@/state/store";
import Swal from "sweetalert2";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core/dist/index.esm";

export default {
  name : 'CompleteModal',

  setup() {
    return { v$: useVuelidate() };
  },

  props : {
    modalData : {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      title: "고객문의",
      data: this.$props.modalData,
      showModal: false,
      type: "write",
      typeOptions: [
        { text: '답변 직접입력', value: "write" },
        { text: '전화상담 완료', value: "phone" },
      ],
      formData: {
        id: "",
        answer: "",
        status: "Completed",
        is_mail: true,
      },
      formSubmitted: false,
    }
  },

  mounted() {
    this.title = this.data.name +' ('+ this.data.phone +')';
    this.loadData();
    this.answerType(this.type);
  },

  validations: function() {
    return {
      formData: {
        answer: {
          required: helpers.withMessage("답변내용을 입력해 주세요.", required)
        },
      }
    }
  },

  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },

    // 데이터 로딩
    loadData() {
      this.showModal = true
      if (this.data.id) {
        this.formData.id = this.data.id
        this.formData.answer = this.data.answer
      }
    },

    // 저장여부 체크
    confirmSubmit() {
      store.dispatch('notification/clear');

      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.formData.$invalid) {
        return false;
      }

      let msg = "상담완료 처리하시겠습니까?";
      Swal.fire({
        text: msg,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니요"
      }).then(result => {
        if (result.value) {
          this.submitForm();
        }
      });
    },

    // 저장
    submitForm() {
      this.formSubmitted = true

      let type = this.data.menuCode
      let params = {
        "id": this.formData.id,
        "answer": this.formData.answer,
        "status": this.formData.status,
        "is_mail": this.formData.is_mail ? 1 : 0,
      }

      this.formSubmitted = false
      store.dispatch("apiInquiry/store", { type, params }).then((result) => {
        this.formSubmitted = false
        Swal.fire(result.message, "", result.success ? "success" : "error");
        this.$emit('loadData');
        document.querySelector("#btn-cancel").click();
      }, () => {
        this.formSubmitted = false
      });
    },

    answerType(type) {
      if (type == 'phone') {
        this.formData.answer = "안녕하세요,\n소중한 가족의 안전지킴이 앱 '도와줘' 고객지원팀입니다.\n\n고객님의 문의사항은 전화상담으로 완료되었습니다.\n\n감사합니다.";
      } else {
        this.formData.answer = this.data.answer;
      }
    },

  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },

  watch: {
    type(newVal) {
      this.answerType(newVal);
    },
  },
}
</script>

<template>
  <b-modal
      id="complete-modal"
      v-model="showModal"
      @show="openModal"
      @hidden="closeModal"
      centered
      :title="title"
      title-class="font-16 fw-bold"
      hide-footer
  >
    <div v-if="notification.message" :class="'alert ' + notification.type">{{ notification.message }}</div>
    <b-form @submit.prevent="confirmSubmit">
      <b-form-group class="mb-3">
        <b-form-radio-group
            class="form-control bg-light"
            v-model="type"
            :options="typeOptions"
        ></b-form-radio-group>
      </b-form-group>
      <b-form-group
          label-for="answer"
          :invalid-feedback="v$.formData.answer.$error ? v$.formData.answer.$errors[0].$message : null"
          :state="!v$.formData.answer.$error"
      >
        <b-form-textarea
            id="answer"
            v-model="formData.answer"
            rows="10"
            style="height:200px;"
            placeholder="답변내용을 입력해 주세요."
            :state="!v$.formData.answer.$error"
            :disabled="formSubmitted"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label-for="is_mail">
        <div class="form-control bg-light">
          <b-form-checkbox id="is_mail" v-model="formData.is_mail">문의자에게 메일을 전송합니다.</b-form-checkbox>
        </div>
      </b-form-group>
      <div class="my-2 text-center">
        <b-button variant="danger" class="mx-1" type="submit" :disabled="formSubmitted">완료</b-button>
        <b-button variant="secondary" class="mx-1" id="btn-cancel" data-bs-dismiss="modal">취소</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
